import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { logo } from '@ftrustee/ui'
import '../Home/App.css'

import FormView from './FormView'

const styles = theme => ({
  root: {
    width: '100%'
  },
  title: {
    fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  checkboxes: {
    width: '50%',
    // maxWidth: '700px',
    backgroundColor: theme.palette.background.paper,
    margin: '10px 0',
    textAlign: 'left'
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing.unit * 2}px`,
    width: '95%'
  },
  elemPrazoCura: {
    display: 'flex',
    width: '523.5px'
    // width: '22.6%'
  },
  elemContagCura: {
    display: 'flex',
    width: '332px'
    // width: '22.6%'
  },
  exerSocLabel: {
    // marginLeft: '60%',
    // width: '80%'
    position: 'relative',
    top: '40px',
    width: '236px',
    right: '235px'
  },
  labelSpacing: {
    marginLeft: '15px'
  },
  labelPeriodForm: {
    margin: '15px'
  },
  alignLeftForm: {
    width: '45%',
    textAlign: 'left'
  },
  verificacaoField: {
    display: 'flex',
    width: '76%',
    marginLeft: '0'
  },
  spacingBetweenFields: {
    margin: '20px 5px'
  },
  trimesterPadding: {
    padding: '0 35px'
  },
  quantWidth: {
    padding: '0 10px'
  },
  dialogForm: {
    width: '100%',
    display: 'block',
    textAlign: 'left'
    // minHeight: '290px',
    // margin: '65px'
  },
  dateTimeField: {
    width: '61%',
    marginRight: '90px'
  },
  fieldSpacing: {
    marginLeft: '20%',
    width: '34%'
  },
  tabSpace: {
    width: 220
  },
  maxTabSize: {
    minWidth: '0'
  },
  fieldYAlign: {
    marginTop: '16px'
  },
  operationName: {
    // fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    width: '100%',
    margin: '40px auto'
  }
})

const Covenants = ({ classes, match, companies, options, ...props }) => {
  console.log(match)

  let type

  if (props.initialValues) {
    // console.log(props.initialValues.type)
    type = props.initialValues.type
    console.log(props)
    console.log(options)
  } else {
    type = 'CARREGANDO...'
  }

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Typography
          variant="h5"
          color="inherit"
          classes={{ root: classes.title }}>
          Controle de Documentação
        </Typography>
      </header> */}
      <p className="App-intro" />
      <FormView
        classes={{
          root: classes.root,
          checkboxes: classes.checkboxes,
          dividers: classes.dividerFullWidth,
          byChecked: classes.exerSocField,
          label: classes.exerSocLabel,
          alignLeftForm: classes.alignLeftForm,
          firstVerify: classes.verificacaoField,
          spacingBetweenFields: classes.spacingBetweenFields,
          trimesterPadding: classes.trimesterPadding,
          dialogForm: classes.dialogForm,
          dateTimeField: classes.dateTimeField,
          fieldSpacing: classes.fieldSpacing,
          tabSpace: classes.tabSpace,
          maxTabSize: classes.maxTabSize,
          quantWidth: classes.quantWidth,
          elemPrazoCura: classes.elemPrazoCura,
          elemContagCura: classes.elemContagCura,
          labelSpacing: classes.labelSpacing,
          labelPeriodForm: classes.labelPeriodForm,
          fieldYAlign: classes.fieldYAlign,
          operationName: classes.operationName
        }}
        company={props.location.state.title}
        type={type}
      />
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(Covenants)
