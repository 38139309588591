import React from 'react'
import PropTypes from 'prop-types'
// import express from 'express'
// import httpproxy from 'express-http-proxy'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import { TextField, SelectField, CheckboxField, DateField } from '@ftrustee/ui'
import { FieldArray } from 'redux-form'
import AXIOS from 'axios'
import DataNotifFields from './DataNotifFields'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
    // overflowX: 'auto'
  },
  table: {
    minWidth: 1018
  },
  foo: {
    float: 'left',
    width: '20px',
    height: '20px',
    margin: '15px auto auto 9px',
    border: '1px solid rgba(0, 0, 0, .2)'
  },
  yellow: {
    background: '#ffff00'
  },
  red: {
    background: '#ff0000'
  },
  tableWrapper: {
    overflowX: 'visible'
  },
  paddingNormal: {
    padding: '10px !important'
  },
  paddingCura: {
    paddingRight: '13px !important'
  },
  timeSheetText: {
    width: '100%',
    marginLeft: '200%'
  }
})

let id = 0
function createData(
  name,
  data,
  df,
  If,
  enquadramento,
  resolvido,
  declaracao,
  dtPzNormal,
  normal,
  dtNotif,
  dtPzCura,
  cura
) {
  id += 1
  return {
    id,
    name,
    data,
    df,
    If,
    enquadramento,
    resolvido,
    declaracao,
    dtPzNormal,
    normal,
    dtNotif,
    dtPzCura,
    cura
  }
}

let Cura
let Normal

class TableTimeSheet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      temp: [],
      rows: [],
      dateFields: [],
      page: 0,
      rowsPerPage: 5
    }
  }

  storeNotifDate = (value, index) => {
    if (value !== undefined) {
      console.log(value)
      console.log(index)

      let a = this.state.dateFields.slice()
      a[index] = value
      this.setState({ dateFields: a })

      // this.setState({
      //   dateFields: [...this.state.dateFields, value]
      // })
    }

    console.log(this.state.dateFields)
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  getTableData = () => {
    // this.forceUpdate()

    let numero = this.props.numeroPeriodo
    //DEFINITIVO- DINAMICO:
    // let inicio = this.props.inicio
    // let fim = this.props.fim

    //TESTE- HARDCODED:
    let inicio = '2014-05-14'
    let fim = '2019-05-29'

    var m_data = new FormData()
    m_data.append('initialDate', inicio)
    m_data.append('finalDate', fim)
    m_data.append('period', numero)
    // var object = {}
    // m_data.forEach(function(value, key) {
    //   object[key] = value
    // })
    // var json = JSON.stringify(object)

    const https = require('https')

    // console.log(https, '1')
    // https.globalAgent.options.rejectUnauthorized = false

    // const url = process.env.PROXY_URL || 'localhost'
    // console.log('PROXY_URL:', url)

    // const app = express()
    // const urlSend = app.post('https://34.73.62.98/api/Calendar/Quarter', function (req, res) {
    //   res.send(m_data)
    // })

    // console.log(urlSend())

    // const BASE_URL = 'https://34.73.62.98/api'

    // const axios = AXIOS.create({
    //   // baseURL: BASE_URL,
    //   httpsAgent: new HttpsProxyAgent('http://localhost:3001/'),
    //   proxy: true,
    //   headers: {
    //     'Accept': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    //   }
    // })

    AXIOS({
      method: 'post',
      url: '/api/Calendar/Quarter',
      // url: '/api/Calendar/Quarter', -- DESCOMENTAR, QUANDO PROXY FUNCIONAR COM GRAPHQL
      contentType: false,
      data: m_data,
      headers: {
        'Content-Type': 'application/json' //; charset=utf-8',
      },
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    })
      .then(response => {
        console.log(response)

        const requestString = response.data

        console.log(JSON.parse(requestString))

        this.setState({ temp: JSON.parse(requestString) })

        var storeMap

        storeMap = Object.entries(this.state.temp).map(
          (key, index) =>
            createData(
              key[1],
              key[0]
                .split('-')
                .reverse()
                .join('-'),
              <CheckboxField name={key[1] + '0'} />,
              <CheckboxField name={key[1] + '1'} />,
              <SelectField
                xs
                name={key[1] + 'name'}
                placeholder="Ok / Quebrou"
                options={['Ok', 'Quebrou']}
              />,
              <CheckboxField name={key[1] + '2'} />,
              <CheckboxField name={key[1] + '3'} />,
              'N/D',
              Normal,
              // <DateField xs={9} name={key[1] + '4'} />,
              // <FieldArray name="descumprimento" component={renderDescumprimento} />,
              // <DateField
              //   name={`${key[1]}.date`}
              //   xs
              //   label="Data notificação:" />,
              <DataNotifFields
                keyIndex={key[1]}
                pos={index}
                storeNotifDate={this.storeNotifDate}
              />,
              <Typography
                style={{ marginRight: '30px' }}
                variant="overline"
                display="block"
                gutterBottom>
                N/D
              </Typography>,
              Cura
            )
          // ]})
          // console.log(key, value)
        )

        this.setState({ rows: storeMap })
      })
      .catch(function(error) {
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log(error.message)
        }
      })
    // https.globalAgent.options.rejectUnauthorized = true
  }

  getDate = (origin, term) => {
    let url =
      term === 'DU'
        ? 'BusinessDateAdd'
        : term === 'DC'
        ? 'CalendarDaysAdd'
        : 'AddMonths'
    let lastParam = term === 'MÊS' ? 'pos' : 'local'
    let lastValue = term === 'MÊS' ? 'true' : 'br'

    if (origin === 'normal') {
      var m_data
      var value

      Object.entries(this.state.temp).map(
        (key, index) => (
          (value = key[0].slice(5, -3)),
          console.log(value),
          (m_data = new FormData()),
          m_data.append('initialDate', key[0]),
          m_data.append(
            term === 'MÊS' ? 'qtMonth' : 'addDays',
            value === '12'
              ? this.props.anual
              : value === '06'
              ? this.props.semestral
              : this.props.trimestral
          ),
          m_data.append(lastParam, lastValue),
          // m_data.append( 'local', 'br' ),
          AXIOS({
            method: 'post',
            url: `/api/Calendar/${url}`,
            // url: '/api/Calendar/BusinessDateAdd', -- DESCOMENTAR, QUANDO PROXY FUNCIONAR COM GRAPHQL
            contentType: false,
            data: m_data,
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            }
            // httpsAgent: new https.Agent({
            //   rejectUnauthorized: false
            // })
          })
            .then(response => {
              console.log(response)

              const requestString = response.data
                .slice(0, -9)
                .split('-')
                .reverse()
                .join('-')

              var data = [...this.state.rows]
              this.state.rows[index].dtPzNormal = requestString
              this.setState({ data })
            })
            .catch(function(error) {
              if (error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
              } else if (error.request) {
                console.log(error.request)
              } else {
                console.log(error.message)
              }
            })
        )
      )
    } else if (origin === 'cura') {
      console.log(this.props.ContagemCura)

      var value
      Object.entries(this.state.rows).map(
        (key, index) => (
          console.log(key[1].dtNotif),
          (value = key[1].dtPzNormal
            .split('-')
            .reverse()
            .join('-')),
          (m_data = new FormData()),
          m_data.append(
            'initialDate',
            this.props.ContagemCura === 'NOTIFICAÇÃO'
              ? this.state.dateFields[index]
              : this.props.ContagemCura === 'NORMAL'
              ? value
              : false
          ),
          m_data.append(
            term === 'MÊS' ? 'qtMonth' : 'addDays',
            this.props.QuantPrazoCura
          ),
          m_data.append(lastParam, lastValue),
          AXIOS({
            method: 'post',
            url: `/api/Calendar/${url}`,
            // url: '/api/Calendar/BusinessDateAdd', -- DESCOMENTAR, QUANDO PROXY FUNCIONAR COM GRAPHQL
            contentType: false,
            data: m_data,
            headers: {
              'Content-Type': 'application/json' //; charset=utf-8',
            }
          })
            .then(response => {
              console.log(response)

              const requestString = response.data
                .slice(0, -9)
                .split('-')
                .reverse()
                .join('-')

              var data = [...this.state.rows]
              this.state.rows[index].dtPzCura = requestString
              this.setState({ data })
            })
            .catch(function(error) {
              if (error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
              } else if (error.request) {
                console.log(error.request)
              } else {
                console.log(error.message)
              }
            })
        )
      )
    }
  }

  componentDidMount() {
    console.log(this.props.inicio)
    console.log(this.props.fim)

    if (this.props.numeroPeriodo && this.props.inicio && this.props.fim) {
      this.getTableData()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.inicio !== prevProps.inicio ||
      this.props.fim !== prevProps.fim ||
      this.props.numeroPeriodo !== prevProps.numeroPeriodo
    ) {
      // this.forceUpdate()
      console.log('atualizou props')
      this.getTableData()
    }

    // if (
    //   (this.props.trimestral !== prevProps.trimestral
    //     && this.props.TrimestralDcDu === 'DU') ||
    //   (this.props.semestral !== prevProps.semestral
    //     && this.props.SemestralDcDu === 'DU') ||
    //   (this.props.anual !== prevProps.anual && this.props.AnualDcDu === 'DU')
    // ) {
    //   this.getDate('normal')
    // }

    if (
      this.props.TrimestralDcDu !== prevProps.TrimestralDcDu &&
      this.props.trimestral
    ) {
      this.getDate('normal', this.props.TrimestralDcDu)
    }

    if (
      this.props.SemestralDcDu !== prevProps.SemestralDcDu &&
      this.props.semestral
    ) {
      this.getDate('normal', this.props.SemestralDcDu)
    }

    if (this.props.AnualDcDu !== prevProps.AnualDcDu && this.props.anual) {
      this.getDate('normal', this.props.AnualDcDu)
    }

    // if (
    //   (this.props.TrimestralDcDu !== prevProps.TrimestralDcDu &&
    //     this.props.trimestral) ||
    //   (this.props.SemestralDcDu !== prevProps.SemestralDcDu &&
    //     this.props.semestral) ||
    //   (this.props.AnualDcDu !== prevProps.AnualDcDu && this.props.anual)
    // ) {
    //   this.getDate('normal')
    // }

    if (
      // (
      //  this.props.QuantPrazoCura !== prevProps.QuantPrazoCura &&
      //  this.props.CuraDcDu === 'DU' &&
      //  this.props.ContagemCura !== undefined
      //   ) ||
      // (this.props.CuraDcDu !== prevProps.CuraDcDu &&
      //   this.props.QuantPrazoCura &&
      //   this.props.CuraDcDu === 'DU' &&
      this.props.ContagemCura !== prevProps.ContagemCura &&
      this.props.ContagemCura !== undefined
    ) {
      this.getDate('cura', this.props.CuraDcDu)
    }
  }

  render() {
    // console.log(this.props)

    const { classes } = this.props

    const { rows, rowsPerPage, page } = this.state

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

    let bodyTable

    if (this.props.numeroPeriodo) {
      bodyTable = (
        <React.Fragment>
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="center">DF</TableCell>
                    <TableCell align="center">IF</TableCell>
                    <TableCell align="center">Enquadramento</TableCell>
                    <TableCell align="center">Resolv.</TableCell>
                    <TableCell align="center">Declar.</TableCell>
                    <TableCell className={classes.paddingNormal} align="center">
                      Dt Pz Normal
                    </TableCell>
                    <TableCell align="center">Normal</TableCell>
                    <TableCell align="center">Dt Notif</TableCell>
                    <TableCell className={classes.paddingCura} align="center">
                      Dt Pz Cura
                    </TableCell>
                    <TableCell align="center">Cura</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {this.state.rows.map(row => ( */}
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.data}</TableCell>
                        <TableCell align="center">{row.df}</TableCell>
                        <TableCell align="center">{row.If}</TableCell>
                        <TableCell align="center">
                          {row.enquadramento}
                        </TableCell>
                        <TableCell align="center">{row.resolvido}</TableCell>
                        <TableCell align="center">{row.declaracao}</TableCell>
                        <TableCell align="center">{row.dtPzNormal}</TableCell>
                        <TableCell
                          align="center"
                          className={classes.foo + ' ' + classes.yellow}>
                          {row.normal}
                        </TableCell>
                        <TableCell align="center">{row.dtNotif}</TableCell>
                        <TableCell align="center">{row.dtPzCura}</TableCell>
                        <TableCell
                          align="center"
                          className={classes.foo + ' ' + classes.red}>
                          {row.cura}
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Itens por página: "
              labelDisplayedRows={({ from, to, count }) =>
                `Exibindo ${from}-${to} de ${count}`
              }
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </React.Fragment>
      )
    } else {
      bodyTable = (
        <FormHelperText className={classes.timeSheetText}>
          Sem dados disponíveis a exibir
        </FormHelperText>
      )
    }

    return (
      // <div>{this.state.value}</div>
      <React.Fragment>{bodyTable}</React.Fragment>
    )
  }
}

// TableTimeSheet.defaultProps = {
//   rows: [],
//   numero: '',
//   dataInicio: '',
//   dataFinal: ''
// }

// SimpleTable.propTypes = {
//   classes: PropTypes.object.isRequired
// }

export default withStyles(styles)(TableTimeSheet)
