'use strict'

const axios = require('axios')

export const quarterEndPoint = (period, dtInit, dtEnd) => {
  return axios({
    method: 'post',
    url:
      'http://service.fincs.com.br:5432/api/ServiceGeneral/Quarter?periodo=' +
      period +
      '&dataInicial=' +
      dtInit +
      '&dataFinal=' +
      dtEnd
  })
    .then(response => {
      // console.log(JSON.stringify(response))

      return response.data
    })
    .then(dataRequest => {
      console.log(JSON.stringify(dataRequest))
      return dataRequest
    })
  // .catch(error => {
  //   console.log(error)

  // })
}
