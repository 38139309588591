import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { gql } from 'apollo-boost'
import { quarterEndPoint } from '../../utils/motorPentagono'

import { reduxForm, formValueSelector } from 'redux-form'
import {
  Form,
  TextField,
  SelectField,
  CheckboxField,
  DateField,
  NumberField
} from '@ftrustee/ui'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import TableTimeSheet from './TableTimeSheet'
import DataNotifFields from './DataNotifFields'

// function TabContainer(props) {
//   return (
//     <Typography component="div" style={{ padding: 8 * 3 }}>
//       {props.children}
//     </Typography>
//   )
// }

const divTableSpace = {
  marginBottom: '15px'
}

// function nameParentMethod (props,nameValue) {
// // props.HandleNameChange(nameValue)
// // console.log(props.HandleNameChange)
// console.log(nameValue)
// }

const FormTemplate = ({
  options,
  onCnpjBlur,
  auditado,
  ContagemCura,
  vencAntecipado,
  TituloInicio,
  TituloVencimento,
  PeriodicidadeDf,
  PeriodicidadeIf,
  PeriodicidadeDeclara,
  MenorPeriodicidade,
  trimestral,
  TrimestralDcDu,
  semestral,
  SemestralDcDu,
  anual,
  AnualDcDu,
  QuantPrazoCura,
  CuraDcDu,
  onChange,
  ...props
}) => {
  // console.log(dF)
  // console.log(iF)
  // console.log(declaracao)

  // console.log(MaiorPeriodicidade)

  // "1", "03/11/2015", "05/07/2018"

  let prazoNormalOpts
  let convertePeriodo
  var TableUpdate

  // if (dataNotifFields) {
  //   console.log(dataNotifFields)
  // }

  // console.log(dataNotifFields)

  if (MenorPeriodicidade === 0) {
    convertePeriodo = '3'

    prazoNormalOpts = (
      <div className={props.classes.dialogForm}>
        <Typography variant="h6" className={props.classes.labelPeriodForm}>
          Formulário Periodicidade:
        </Typography>
        <Grid container xs={12}>
          <FormControlLabel
            className="marginRight"
            control={
              <div className="flex">
                <NumberField
                  item
                  xs={1}
                  name="trimestral"
                  label="TRIMESTRAL"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  className={`${props.classes.trimesterPadding} ${
                    props.classes.fieldYAlign
                  }`}
                  name="TrimestralDcDu"
                  placeholder="MÊS / DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
                <NumberField
                  item
                  xs={1}
                  name="semestral"
                  label="SEMESTRAL"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  className={`${props.classes.trimesterPadding} ${
                    props.classes.fieldYAlign
                  }`}
                  name="SemestralDcDu"
                  placeholder="MÊS / DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
                <NumberField
                  item
                  xs={1}
                  name="anual"
                  label="ANUAL"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  className={`${props.classes.trimesterPadding} ${
                    props.classes.fieldYAlign
                  }`}
                  name="AnualDcDu"
                  placeholder="MÊS / DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
              </div>
            }
            label="Prazo Normal"
            labelPlacement="start"
          />
        </Grid>
        <Grid container xs={12}>
          <FormControlLabel
            className="marginRight"
            control={
              <div className="flex">
                <NumberField
                  className={props.classes.trimesterPadding}
                  item
                  xs={5}
                  name="QuantPrazoCura"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  xs={5}
                  className={props.classes.trimesterPadding}
                  name="CuraDcDu"
                  placeholder="MÊS /DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
              </div>
            }
            label="Prazo Cura"
            labelPlacement="start"
          />
        </Grid>
        <FormControlLabel
          className={props.classes.elemContagCura}
          control={
            <div className="flex">
              <SelectField
                xs
                className={props.classes.trimesterPadding}
                name="ContagemCura"
                placeholder="Normal / Notificação"
                options={['Normal', 'Notificação']}
              />
            </div>
          }
          label="Contagem Cura"
          labelPlacement="start"
        />
        <FormControlLabel
          className={props.classes.checkboxes}
          control={
            <div className="flex">
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="vencAntecipado"
                id="vencAntecipado"
              />
            </div>
          }
          label="Vencimento Antecipado"
        />
        {/* <FormControlLabel
          className="marginRight"
          control={
            <div className="flex">
              <DateField
                className={props.classes.trimesterPadding}
                xs
                name="TituloInicio"
                placeholder="Inicio"
              />
              <DateField
                xs
                className={props.classes.trimesterPadding}
                name="TituloVencimento"
                placeholder="Vencimento"
              />
            </div>
          }
          label="Título Início/Vencimento"
          labelPlacement="start"
        /> */}
      </div>
    )
  } else if (MenorPeriodicidade === 1) {
    convertePeriodo = '6'

    prazoNormalOpts = (
      <div className={props.classes.dialogForm}>
        <Typography variant="h6" className={props.classes.labelPeriodForm}>
          Formulário Periodicidade:
        </Typography>
        <Grid container xs={12}>
          <FormControlLabel
            className="marginRight"
            control={
              <div className="flex">
                <NumberField
                  item
                  xs={2}
                  className={props.classes.quantWidth}
                  name="semestral"
                  label="SEMESTRAL"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  xs={3}
                  className={`${props.classes.trimesterPadding} ${
                    props.classes.fieldYAlign
                  }`}
                  name="SemestralDcDu"
                  placeholder="MÊS / DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
                <NumberField
                  className={props.classes.quantWidth}
                  xs={2}
                  name="anual"
                  label="ANUAL"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  xs={3}
                  className={`${props.classes.trimesterPadding} ${
                    props.classes.fieldYAlign
                  }`}
                  name="AnualDcDu"
                  placeholder="MÊS / DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
              </div>
            }
            label="Prazo Normal"
            labelPlacement="start"
          />
        </Grid>
        <Grid container xs={12}>
          <FormControlLabel
            // className="marginRight"
            className={props.classes.elemPrazoCura}
            control={
              <div className="flex">
                <NumberField
                  className={props.classes.trimesterPadding}
                  item
                  xs={5}
                  name="QuantPrazoCura"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  xs={5}
                  className={props.classes.trimesterPadding}
                  name="CuraDcDu"
                  placeholder="MÊS /DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
              </div>
            }
            label="Prazo Cura"
            labelPlacement="start"
          />
        </Grid>
        <FormControlLabel
          className={props.classes.elemContagCura}
          control={
            <div className="flex">
              <SelectField
                xs
                className={props.classes.trimesterPadding}
                name="ContagemCura"
                placeholder="Normal / Notificação"
                options={['Normal', 'Notificação']}
              />
            </div>
          }
          label="Contagem Cura"
          labelPlacement="start"
        />
        <FormControlLabel
          className={props.classes.checkboxes}
          control={
            <div className="flex">
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="vencAntecipado"
                id="vencAntecipado"
              />
            </div>
          }
          label="Vencimento Antecipado"
        />
        {/* <FormControlLabel
          className="marginRight"
          control={
            <div className="flex">
              <DateField
                className={props.classes.trimesterPadding}
                xs
                name="TituloInicio"
                placeholder="Inicio"
              />
              <DateField
                xs
                className={props.classes.trimesterPadding}
                name="TituloVencimento"
                placeholder="Vencimento"
              />
            </div>
          }
          label="Título Início/Vencimento"
          labelPlacement="start"
        /> */}
      </div>
    )
  } else if (MenorPeriodicidade === 2) {
    convertePeriodo = '12'

    prazoNormalOpts = (
      <div className={props.classes.dialogForm}>
        <Typography variant="h6" className={props.classes.labelPeriodForm}>
          Formulário Periodicidade:
        </Typography>
        <Grid container xs={12}>
          <FormControlLabel
            className="marginRight"
            control={
              <div className="flex">
                <NumberField
                  item
                  xs={3}
                  className={props.classes.quantWidth}
                  name="anual"
                  label="ANUAL"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  xs={7}
                  className={`${props.classes.trimesterPadding} ${
                    props.classes.fieldYAlign
                  }`}
                  name="AnualDcDu"
                  placeholder="MÊS / DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
              </div>
            }
            label="Prazo Normal"
            labelPlacement="start"
          />
        </Grid>
        <Grid container xs={12}>
          <FormControlLabel
            // className="marginRight"
            className={props.classes.elemPrazoCura}
            control={
              <div className="flex">
                <NumberField
                  className={props.classes.trimesterPadding}
                  item
                  xs={5}
                  name="QuantPrazoCura"
                  placeholder="Quantidade"
                />
                <SelectField
                  item
                  xs={5}
                  className={props.classes.trimesterPadding}
                  name="CuraDcDu"
                  placeholder="MÊS /DC / DU"
                  options={['MÊS', 'DC', 'DU']}
                />
              </div>
            }
            label="Prazo Cura"
            labelPlacement="start"
          />
        </Grid>
        <FormControlLabel
          className={props.classes.elemContagCura}
          control={
            <div className="flex">
              <SelectField
                xs
                className={props.classes.trimesterPadding}
                name="ContagemCura"
                placeholder="Normal / Notificação"
                options={['Normal', 'Notificação']}
              />
            </div>
          }
          label="Contagem Cura"
          labelPlacement="start"
        />
        <FormControlLabel
          className={props.classes.checkboxes}
          control={
            <div className="flex">
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="vencAntecipado"
                id="vencAntecipado"
              />
            </div>
          }
          label="Vencimento Antecipado"
        />
        {/* <FormControlLabel
          className="marginRight"
          control={
            <div className="flex">
              <DateField
                className={props.classes.trimesterPadding}
                xs
                name="TituloInicio"
                placeholder="Inicio"
              />
              <DateField
                xs
                className={props.classes.trimesterPadding}
                name="TituloVencimento"
                placeholder="Vencimento"
              />
            </div>
          }
          label="Título Início/Vencimento"
          labelPlacement="start"
        /> */}
      </div>
    )
  }

  TableUpdate = (
    <TableTimeSheet
      numeroPeriodo={convertePeriodo}
      inicio={TituloInicio}
      fim={TituloVencimento}
      trimestral={trimestral}
      semestral={semestral}
      anual={anual}
      TrimestralDcDu={TrimestralDcDu}
      SemestralDcDu={SemestralDcDu}
      AnualDcDu={AnualDcDu}
      QuantPrazoCura={QuantPrazoCura}
      CuraDcDu={CuraDcDu}
      ContagemCura={ContagemCura}
    />
  )
  return (
    <div className="covenants-form">
      <Form {...props}>
        <FormLabel component="legend" className={props.classes.operationName}>
          {props.company}
        </FormLabel>
        <ExpansionPanel className={props.classes.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Demonstração Financeira</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FormControlLabel
              control={
                <div>
                  <Tabs
                    value={PeriodicidadeDf}
                    name="PeriodicidadeDf"
                    className={props.classes.tabSpace}
                    onChange={(event, value) =>
                      onChange({ name: 'PeriodicidadeDf', value })
                    }>
                    <Tab className={props.classes.maxTabSize} label="TRI," />
                    <Tab className={props.classes.maxTabSize} label="SEM," />
                    <Tab className={props.classes.maxTabSize} label="ANO" />
                  </Tabs>
                </div>
              }
              label="Periodicidade:"
              labelPlacement="start"
            />

            <FormControlLabel
              className={props.classes.fieldSpacing}
              control={
                <div>
                  <SelectField
                    xs
                    className={props.classes.labelSpacing}
                    name="exercicio"
                    placeholder="Dezembro"
                    options={[
                      'Janeiro',
                      'Fevereiro',
                      'Março',
                      'Abril',
                      'Maio',
                      'Junho',
                      'Julho',
                      'Agosto',
                      'Setembro',
                      'Outubro',
                      'Novembro',
                      'Dezembro'
                    ]}
                  />
                  <FormHelperText className={props.classes.labelSpacing}>
                    lista de Meses no Ano (Default = DEZ)
                  </FormHelperText>
                </div>
              }
              label="Exercício Social:"
              labelPlacement="start"
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel className={props.classes.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Índice Financeiro</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={props.classes.alignLeftForm}>
              <FormControlLabel
                className={props.classes.checkboxes}
                control={
                  <CheckboxField
                    color="primary"
                    // inputRef={this.checkboxRef}
                    // disabled={!required}
                    // name={`${name}.checked`}
                    name="auditado"
                    id="auditado"
                  />
                }
                label="Auditado"
              />
              <TextField xs name="clausula" label="Clausula" />
              <FormControlLabel
                className={props.classes.spacingBetweenFields}
                control={
                  <div>
                    <Tabs
                      value={PeriodicidadeIf}
                      name="PeriodicidadeIf"
                      className={props.classes.tabSpace}
                      onChange={(event, value) =>
                        onChange({ name: 'PeriodicidadeIf', value })
                      }>
                      <Tab className={props.classes.maxTabSize} label="TRI," />
                      <Tab className={props.classes.maxTabSize} label="SEM," />
                      <Tab className={props.classes.maxTabSize} label="ANO" />
                    </Tabs>
                  </div>
                }
                label="Periodicidade:"
                labelPlacement="start"
              />
              <FormControlLabel
                className={props.classes.firstVerify}
                control={
                  <div className="flex">
                    <SelectField
                      xs={8}
                      className={props.classes.trimesterPadding}
                      name="Primeira verificação"
                      placeholder="Trimestre"
                      options={[
                        'Mar/1º Tri',
                        'Jun/2º Tri',
                        'Set/3º Tri',
                        'Dez/4º Tri'
                      ]}
                    />
                    <TextField xs={3} name="Ano" placeholder="Ano" />
                  </div>
                }
                label="Primeira verificação"
                labelPlacement="start"
              />

              <FormHelperText>
                informar TRI - caso não informado, será considerado 1º TRI
                depois da emissão.
              </FormHelperText>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel className={props.classes.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title">Declaração</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FormControlLabel
              control={
                <div>
                  <Tabs
                    value={PeriodicidadeDeclara}
                    name="PeriodicidadeDeclara"
                    className={props.classes.tabSpace}
                    onChange={(event, value) =>
                      onChange({ name: 'PeriodicidadeDeclara', value })
                    }>
                    <Tab className={props.classes.maxTabSize} label="TRI," />
                    <Tab className={props.classes.maxTabSize} label="SEM," />
                    <Tab className={props.classes.maxTabSize} label="ANO" />
                  </Tabs>
                </div>
              }
              label="Periodicidade:"
              labelPlacement="start"
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* <Divider component="input" className={props.classes.dividers} /> */}

        <div className="auditPopUp">{prazoNormalOpts}</div>

        {/* <DataNotifFields/> */}

        <ExpansionPanel className={props.classes.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title">Formulário tabela</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="timeSheetTable" style={{ marginLeft: '12%' }}>
              {TableUpdate}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* <AutocompleteField
            name="groupId"
            label="Grupo"
            isClearable={true}
            xs={12}
            sm={6}
            options={options.groups}
            />
            <TextField xs name="website" label="Website" />
            <TextField xs name="relationship" label="Relação" />
            <TextField xs name="industry" label="Indústria" />
            <SelectField
            xs
            name="status"
            label="Situação"
            options={['Ativa', 'Inativa', 'Em Recuperação Judicial']}
            />
            <SelectField
            xs
            name="held"
            label="Propriedade"
            options={['Privada', 'Pública', 'Mista']}
            />
            <SelectField
            xs
            name="capital"
            label="Capital"
            options={['Aberto', 'Fechado']}
            />
            <TextField name="notes" label="Notas" multiline rowsMax="4" xs={12} />
            <Grid item xs={12}>
            <Grid container spacing={16}>
            <PhoneList />
            </Grid>
            </Grid>
            <AddressList /> */}
      </Form>
    </div>
  )
}

FormTemplate.propTypes = {
  options: PropTypes.object.isRequired
}

FormTemplate.defaultProps = {
  options: {}
}

// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired
// }

const FormView = compose(
  reduxForm({ form: 'company' }),
  withApollo,
  connect((state, { autofill }) => {
    const selector = formValueSelector('company')
    // const menorPeriodState = ( value) => {

    //   let ArrayStates = new Array (dF, iF, declaracao)

    //   let filterResult = ArrayStates.filter(function(state){
    //     return typeof state !== 'undefined';
    //    });

    //   console.log(filterResult.toString())

    //   let validate

    //   if(typeof filterResult !== 'undefined'){
    //   if (filterResult.length < 1) {
    //     validate = filterResult.toString()
    //   } else {
    //     validate = Math.min(filterResult.toString())
    //   }
    // }
    //     return validate

    // let validate

    // if (typeof(value) == 'undefined' || value === null) {
    //   validate = isNaN(value) ? -Infinity : value
    // }else{
    //   validate = value
    // }

    // return value !== undefined
    // }
    const {
      auditado,
      ContagemCura,
      vencAntecipado,
      propriedade,
      TituloInicio,
      TituloVencimento,
      QuantPrazoCura,
      CuraDcDu,
      DcDuMes,
      PeriodicidadeDf,
      PeriodicidadeIf,
      PeriodicidadeDeclara,
      MenorPeriodicidade,
      trimestral,
      TrimestralDcDu,
      semestral,
      SemestralDcDu,
      anual,
      AnualDcDu
    } = selector(
      state,
      'declaracao',
      'auditado',
      'ContagemCura',
      'vencAntecipado',
      'propriedade',
      'TituloInicio',
      'TituloVencimento',
      'QuantPrazoCura',
      'CuraDcDu',
      'PeriodicidadeDf',
      'PeriodicidadeIf',
      'PeriodicidadeDeclara',
      'MenorPeriodicidade',
      'trimestral',
      'TrimestralDcDu',
      'semestral',
      'SemestralDcDu',
      'anual',
      'AnualDcDu'
    )
    return {
      auditado,
      ContagemCura,
      vencAntecipado,
      propriedade,
      TituloInicio,
      TituloVencimento,
      QuantPrazoCura,
      CuraDcDu,
      DcDuMes,
      PeriodicidadeDf,
      PeriodicidadeIf,
      PeriodicidadeDeclara,
      MenorPeriodicidade,
      trimestral,
      TrimestralDcDu,
      semestral,
      SemestralDcDu,
      anual,
      AnualDcDu,
      onChange: target => {
        // console.log(target.name, target.value)

        if (target.name === 'PeriodicidadeDf') {
          autofill('PeriodicidadeDf', target.value)
        } else if (target.name === 'PeriodicidadeIf') {
          autofill('PeriodicidadeIf', target.value)
        } else if (target.name === 'PeriodicidadeDeclara') {
          autofill('PeriodicidadeDeclara', target.value)
          console.log(PeriodicidadeDeclara)
        }

        if (
          target.name === 'PeriodicidadeDf' ||
          target.name === 'PeriodicidadeIf' ||
          target.name === 'PeriodicidadeDeclara'
        ) {
          autofill('trimestral', '')
          autofill('TrimestralDcDu', '')
          autofill('semestral', '')
          autofill('SemestralDcDu', '')
          autofill('anual', '')
          autofill('AnualDcDu', '')
          autofill('QuantPrazoCura', '')
          autofill('CuraDcDu', '')
        }

        let ArrayStates = [
          PeriodicidadeDf,
          PeriodicidadeIf,
          PeriodicidadeDeclara
        ].filter(function(value) {
          return value !== undefined
        })

        console.log(ArrayStates)

        if (ArrayStates.length <= 1) {
          autofill('MenorPeriodicidade', target.value)
          console.log('contains undefined')
        } else {
          autofill('MenorPeriodicidade', Math.min.apply(null, ArrayStates))
          console.log('not contains undefined')
        }

        console.log('Menor Periodicidade: ' + MenorPeriodicidade)
      }
    }
  }),
  withHandlers({
    onCnpjBlur: ({
      client,
      initialized,
      autofill,
      isLoading,
      dispatch
    }) => async (event, newValue, previousValue, name) => {
      const cnpj = newValue.replace(/\D/g, '')
      if (initialized || !cnpj) return
      dispatch({
        type: 'APP_LOADING',
        payload: true
      })
      const { data } = await client.query({
        query: gql`
          query find($cnpj: String!) {
            findCompany(cnpj: $cnpj)
          }
        `,
        variables: { cnpj }
      })
      const company = data.findCompany
      for (var prop in data.findCompany) {
        if (company.hasOwnProperty(prop)) {
          console.log('FormView#onCnpjBlur', prop, company[prop])
          autofill(prop, company[prop])
        }
      }
      dispatch({
        type: 'APP_LOADING',
        payload: false
      })
    }
  })
)(FormTemplate)

export default FormView
