import React, { useState } from 'react'
import { lifecycle } from 'recompose'
import { FieldArray } from 'redux-form'

import Grid from '@material-ui/core/Grid'

import { TextField, DateField } from '@ftrustee/ui'

const renderDataNotif = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(
  ({
    fields,
    position,
    keyIndex,
    storeNotifDate,
    meta: { error, submitFailed }
  }) => {
    const [date, setDate] = useState(date)

    // console.log(date)

    storeNotifDate(date, position)

    // dateFields = date
    // console.log(dateFields)

    return (
      <React.Fragment>
        {/* {fields.map((member, index) => (  */}
        <Grid
          // style={{ height: 0 }}
          container
          xs={12}>
          <DateField
            name={`${keyIndex}.date`}
            xs
            onChange={(e, value) => setDate(value)}
            label="Data notificação:"
          />
        </Grid>
        {/* )} */}
      </React.Fragment>
    )
  }
)

let DataNotifFields = props => {
  const { pos, keyIndex, storeNotifDate } = props

  return (
    <FieldArray
      name="dataNotifFields"
      component={renderDataNotif}
      position={pos}
      keyIndex={keyIndex}
      storeNotifDate={storeNotifDate}
    />
  )
}

export default DataNotifFields

// import React, { useState } from 'react'
// import { lifecycle, withState } from 'recompose'
// import { FieldArray, reduxForm, change } from 'redux-form'

// import Grid from '@material-ui/core/Grid'
// import Icon from '@material-ui/core/Icon'
// import IconButton from '@material-ui/core/IconButton'

// import { TextField, SelectField, DateField, NumberField } from '@ftrustee/ui'

// // let date

// // const handleInitialDate = (value) => {
// //   console.log(value)
// //   date = value
// //   // console.log(date)
// // }

// const renderRefs = lifecycle({
//   componentDidMount() {
//     this.props.fields.length === 0 && this.props.fields.push()
//   }
// })(
//   ({
//     fields,
//     index,
//     state,
//     meta: { error, submitFailed },
//     ...props
//   }) => {

//     return (
//       <React.Fragment>
//         {fields.map((member) => (
//           <div>
//             {/* <Grid style={{ height: 0 }} item xs={12}>
//               <IconButton
//                 color="secondary"
//                 aria-label="adicionar"
//                 // style={{ marginTop: 10, marginLeft: '93%' }}
//                 style={{
//                   width: '50px',
//                   position: 'relative',
//                   left: '100%'
//                 }}
//                 // onClick={() =>
//                 //   index > 0 ? (handleObject(index), fields.remove(index)) : (handleObject(index, fields), fields.push())
//                 // }
//                 onClick={(event, value) =>
//                   handlerControl({
//                     event,
//                     fields,
//                     value,
//                     index,
//                     enableRules
//                   })
//                 }
//               // onClick={() => resetFields(index, fields, enableRules)}
//               >
//                 <Icon>{index > 0 ? 'remove_circle' : 'add_circle'}</Icon>
//               </IconButton>
//             </Grid> */}
//             <DateField
//               name={`${index}.date`}
//               xs
//               label="Data notificação:" />
//           </div>
//         ))}
//       </React.Fragment>
//     )
//   }
// )

// let DataNotif = props => {
//   const {
//     index,
//     state,
//     pristine,
//     reset,
//     submitting
//   } = props

//   return (
//     // <Grid item xs={12}>
//     <Grid container>
//       {/* <FieldArray
//         name="cadControlFields"
//         enableRules={enableRules}
//         handleChangeCadControl={handleChangeCadControl}
//         handlerControl={handlerControl}
//         copyState={copyState}
//         component={renderRefs}
//       /> */}

//       <FieldArray
//         name="dataNotif"
//         index={index}
//         state={state}
//         component={renderRefs}
//       />

//     </Grid>
//     // </Grid>
//   )
// }

// export default (CadControlFields = reduxForm({
//   form: 'company',// a unique identifier for this form
//   validate
// })(CadControlFields))

// const CadControlFields = () => (
//   <FieldArray name="cadControlFields" component={renderMembers} />
// )

// export default DataNotif

// import React from 'react'
// import { FieldArray, reduxForm } from 'redux-form'
// import { DateField } from '@ftrustee/ui'
// import { lifecycle, withState } from 'recompose'

// const renderMembers = lifecycle({
//   componentDidMount() {

//     this.props.fields.length === 0 && this.props.fields.push()
//   }
// })(({ fields, keyIndex, meta: { error, submitFailed }, ...props }) => {
//   return (
//     <div>
//     {/* {fields.map((member, index) =>  (  */}
//       {/* <div key={index}> */}
//         <DateField
//           name={`${keyIndex}.date`}
//           xs
//           label="Data notificação:"
//           />
//       {/* </div>
//     // ))}*/}
//   </div>
//   )
// })

// const DataNotifFields = props => {
//   const { keyIndex, handleSubmit, pristine, reset, submitting } = props

//   console.log(keyIndex)

//   return (
//     <form >
//       <FieldArray keyIndex={keyIndex} name="members" component={renderMembers} />
//     </form>
//   )
// }

// export default reduxForm({
//   form: 'dataNotif', // a unique identifier for this form
// })(DataNotifFields)
